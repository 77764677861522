<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="EIS Slow Cook-off Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="EIS Slow Cook-off Test Description"
              size="medium"
            />
            <p class="p-light">
              An impingement gun is used to fire a 9 gram piece of consolidated
              substance traveling 150 m/s into a manometric bomb. The pressure
              rate of rise is recorded.
            </p>
            <a
              target="_blank"
              :href="require('../../assets/UN/setup-cook-off-min.jpg')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/UN/setup-cook-off-min.jpg"
              />
            </a>
            <p class="p-light">
              The substance is confined in a pipes of internal diameter of 4.5cm
              and 20cm long with end caps and then it is slowly heated to 365°C.
              Above is shown an example pipe and heater.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The EIS slow cook-off test is completed to determine if the
              candidate for an extremely insensitive substance (EIS) explodes or
              reacts violently when slowly heated to ignition. Detonation or
              violent reaction with the end caps fragmented or the pipe
              fragmented into more than 3 pieces indicates the substance is not
              a candidate as an EIS.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Sample configuration</td>
                    <td>Simulate an insensitive munition</td>
                    <td></td>
                    <td>
                      Each test sample is confined in a seamless steel pipe of
                      20 cm in length and internal diameter of 4.5cm (±10%) and
                      wall thickness of 4mm (±10%). The sample should closely
                      fit with similar dimensions. The pipe also has steel or
                      cast iron end caps at least as strong as the pipe, torqued
                      to 204 Nm.
                    </td>
                  </tr>
                  <tr>
                    <td>Oven</td>
                    <td>
                      Exposes the substance to a slowly increasing temperature
                      until it ignites
                    </td>
                    <td></td>
                    <td>
                      The oven temperature is increased by 3.3°C per hour to
                      365°C. The temperature of the oven and of the pipe is
                      continuously recorded.
                    </td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>Ensure proper classification</td>
                    <td></td>
                    <td>
                      Three trials are completed unless a positive result
                      (detonation or violent reaction) is observed earlier.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Mass explosion</td>
                    <td>
                      Observation of consumption rate, video camera evidence,
                      and visual post test inspection. Optional use of blast
                      gauges and high-speed video.
                    </td>
                    <td>
                      Comparing observations against the definition of a mass
                      explosion hazard (substantial proportion of the whole
                      [Subjective] explodes) [Mass explosion hazard: Not an EIS]
                    </td>
                  </tr>
                  <tr>
                    <td>Fragments</td>
                    <td>
                      Observation of the number of fragments and if the end caps
                      were fragmented
                    </td>
                    <td>
                      Fragmentation of the end caps or the tube or pipe is
                      fragmented into more than 3 pieces[Mass explosion hazard:
                      Not an EIS]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <a
              target="_blank"
              :href="require('../../assets/UN/results-cookoff-min.jpg')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/UN/results-cookoff-min.jpg"
              />
            </a>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
